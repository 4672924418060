import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Chip
} from '@mui/material'
import './App.css'

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date("2024-05-30") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span
      style={{
        fontSize:'20px',
        fontWeight:'bold'

      }}
      key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div>
      {timerComponents.length ? timerComponents : <span>Time's up!</span>}
    </div>
  );
};
function App() {
  return (
    <Box
    id='hero'
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#FFF', // Text color

 
    }}
    >
   <Box sx={{
    zIndex:1400
   }}>
   
  <Typography variant="h3" gutterBottom>
          <span
          style={{
            color:'#d32f2f'
          }}
          >GardenGrill</span> Cafe
          <CountdownTimer /> 
      </Typography>
        <Chip
        label='Coming Soon'
        color='error'
        size='lg'
        variant='contained'
        />

</Box>   

    </Box>
  
  );
}

export default App;
